<template>
  <div class="portfolio__container" id="portfolio">
<!--
    <img src="@/assets/img/pab.svg" alt="" class="pab">
-->
    <img src="https://dbwImages.b-cdn.net/pab.svg" alt="" class="pab">


<!--
    <img src="@/assets/img/portfolio__title__img.png" alt="" class="title__img">
-->
    <img src="https://dbwImages.b-cdn.net/portfolio__title__img.png" alt="" class="title__img">

    <img src="@/assets/img/portfolio__title__img__mobile.png" alt="" class="title__img__mobile">


<!--
    <img src="~@/assets/img/pbg.png" alt="" class="background__mobile">
-->
    <img src="https://dbwImages.b-cdn.net/pbg.png" alt="" class="background__mobile">


<!--
    <img src="~@/assets/img/bg__two__portfolio.svg" alt="" class="background__mobile__two">
-->
    <img src="https://dbwImages.b-cdn.net/bg__two__portfolio.svg" alt="" class="background__mobile__two">


    <div class="doing__title__portfolio ">
      <h2 class="doing__title__text__portfolio ">
        PORTFOLIO
      </h2>
      <div class="doing__span__container__portfolio">
        <span class="doing__span__one__portfolio "></span>
        <span class="doing__span__two__portfolio "></span>
      </div>
      <p class="doing__title__text__small__text__portfolio ">
        Our lofty goal is fueled by the
        trust Digital Beauty Work has gained over just
        a few years of its existence.
        <br>
        <br>
        Cardi B, Chris Brown, Katy Perry,
        Paris Hilton, Nicki Minaj, Beyonce, JLo and
        Ariana Grande — that’s not the
        full list of celebrities
        we are happy to work with.
      </p>
    </div>

    <router-link :to="{name: 'portfolio'}">
      <div class="doing__portfolio__button">
        <img src="@/assets/img/button__portfolio__mobile.svg" alt="" class="portfolio__button__mobile">
      </div>
    </router-link>


    <div class="portfolio__title">
      <p class="portfolio__title__text portfolio__title__text__position">PORTFOLIO</p>
      <div class="portfolio__span__container">
        <span class="portfolio__span__one"></span>
        <span class="portfolio__span__two"></span>
      </div>
      <p class="portfolio__title__text__small__text">
        Our lofty goal is fueled by the trust Digital Beauty Work has gained over just a few years of its existence.
        <br>
        <br>
        Cardi B, Chris Brown, Katy Perry, Paris Hilton,<br>
        Nicki Minaj, and Ariana Grande — that’s not <br>
        the full list of celebrities we are happy to work with.
      </p>
    </div>


    <div v-if="listLeft" class="portfolio_block__redisign__one">

      <div class="redisign__one"
           @click="showVideoModal(left)"
           :id="`prod_left${index + 1}`"
           v-for="(left, index) in listLeft"
           :key="index"
           @mouseover="playVideoOne(index)"
           @mouseout="pauseVideoOne(index)"

      >
        <span class="shadow_container"></span>
        <div v-if="listLeft" class="video__wrapper__one">
          <img src="@/assets/img/play__portfolio__home.png" alt="" class="play">
          <p class="video__title">{{ left.title }}</p>
        </div>

        <span class="redisign__photo__one__container" v-show="$data['left'+index]">
              <img class="redisign__photo__one" :src="left.video.preview_image"
                   :alt="left.index">
            </span>

        <span v-if="left.video" v-show="!$data['left'+index]" class="video__wrapper">


                <video
                    :ref="'myVideoOne' + index"
                    tabindex="0"
                    autobuffer="autobuffer"
                    playsinline
                    muted
                    loop
                    class="redisign__video__one"
                    preload="preload"
                    :controls="shouldShowControls"
                >

                  <source type="video/mp4;" :src="left.video.link"/>
                </video>


            </span>
      </div>


    </div>

    <div v-if="listRight" class="portfolio_block__redisign__two">

      <div class="redisign__two"
           @click="showVideoModal(right)"
           :id="`prod_right${index + 1}`"
           v-for="(right, index) in listRight"
           :key="index"
           @mouseover="playVideoTwo(index)"
           @mouseout="pauseVideoTwo(index)"
      >
        <span class="shadow_container_two"></span>

        <div class="video__wrapper__two">
          <img src="@/assets/img/play__portfolio__home.png" alt="" class="play">
          <p class="video__title">{{ right.title }}</p>
        </div>

        <span class="redisign__photo__two__container" v-show="$data['right'+index]">
              <img class="redisign__photo__two" :src="right.video.preview_image"
                   :alt="right.index">
            </span>
        <span v-if="right.video" v-show="!$data['right'+index]" class="video__wrapper">


                <video
                    :ref="'myVideoTwo' + index"
                    tabindex="0"
                    autobuffer="autobuffer"
                    playsinline
                    muted
                    loop
                    class="redisign__video__two"
                    preload="preload"
                    :controls="shouldShowControls"
                >

                  <source class="source" type="video/mp4;" :src="right.video.link"/>
                </video>


            </span>
      </div>

    </div>


    <router-link :to="{name: 'portfolio'}">
      <div class="button__portfolio">
        <ButtonsRouter
            :text="'Show more'"
        />

        <!--    <img src="@/assets/img/show__button__portfolio.svg" alt="" class="show">
            <img src="@/assets/img/show__button__arrow__portfolio.svg" alt="" class="arrow">-->
      </div>
    </router-link>


    <modal-video-you-tube v-if="$route.params.slug" :video-id="videoId" :image-full="imageFull"/>

  </div>
</template>

<script>
/*import ButtonsRouter from "./Buttons-Router";
import ModalVideoYouTube from "./ModalVideoYouTube";*/
const ButtonsRouter = () => import('./Buttons-Router');
const ModalVideoYouTube = () => import('./ModalVideoYouTube');

import {mapGetters} from "vuex";

export default {
  name: "PortfolioHomeRedisigne",
  components: {ModalVideoYouTube, ButtonsRouter},
  computed: {
    ...mapGetters([
      'homeContentPortfolioHome',
      'homeContentLoad'
    ]),
    shouldShowControls() {
      return window.innerWidth > 980;
    },
  },
  data() {
    return {
      /*
            portfolioHome: null,
      */
      listLeft: [],
      left1: true,
      left0: true,
      right0: true,
      right1: true,

      listRight: [],
      videoId: '',
      imageFull: {
        link: '',
        alt: '',
      },
    };
  },

  mounted() {
    this.fillVideoContent();
  },

  watch: {
    homeContentPortfolioHome: function () {
      this.fillVideoContent();
    },
  },
  methods: {
    playVideoOne(index) {
      this.$data['left'+index] = false
      this.$refs['myVideoOne' + index][0].play();
    },
    pauseVideoOne(index) {
      this.$data['left'+index] = true
      this.$refs['myVideoOne' + index][0].pause();
    },
    playVideoTwo(index) {
      this.$data['right'+index] = false
      this.$refs['myVideoTwo' + index][0].play();
    },
    pauseVideoTwo(index) {
      this.$data['right'+index] = true
      this.$refs['myVideoTwo' + index][0].pause();
    },
    fillVideoContent() {
      if (this.homeContentPortfolioHome) {
        this.listLeft = this.homeContentPortfolioHome.blocks.left.projects;
        this.listRight = this.homeContentPortfolioHome.blocks.right.projects;
      }
    },
    showVideoModal(el) {
      this.$router.replace(`/home/${el.slug}`);
      if (el) {
        let linkSplit = el.link.split('/')
        this.videoId = linkSplit[linkSplit.length - 1]
      } else {
        this.imageFull = el.image;
      }
    },
  },

}
</script>

<style scoped lang="scss">
.portfolio__container {
  overflow: hidden;
  width: 100%;
  height: 98.542vw;
  position: relative;
  /*
  background-image: url("~@/assets/img/pbg.png");
  */
  background-image: url("https://dbwImages.b-cdn.net/pbg.png");
  background-size: contain;
}

.pab {
  position: absolute;
  right: -2vw;
  z-index: -1;
  top: -37vw;
}

.title__img {
  width: 44.375vw;
  height: 83.646vw;
  position: absolute;
  top: 0;
  right: 0;
}


.portfolio__title {
  position: absolute;
  right: 8.281vw;
  top: 33.938vw;
  width: 25.156vw;
  height: 17.083vw;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.portfolio__title__text {
  font-family: 'Benzin-ExtraBold';
  color: rgba(255, 255, 255, 1);
  font-size: 2.083vw;
  text-align: right;
}

.portfolio__span__container {
  margin-top: 2.083vw;
  display: flex;
  width: 20.885vw;
  height: 0.156vw;
}

.portfolio__span__one {
  height: 0.156vw;
  width: 10.313vw;
  background: rgba(255, 255, 255, 1);
}

.portfolio__span__two {
  height: 0.156vw;
  width: 10.313vw;
  background: rgba(219, 16, 35, 1);
}

.portfolio__title__text__small__text {
  margin-top: 2.604vw;
  font-size: 0.938vw;
  font-family: 'Benzin-Regular';
  line-height: 140%;
  text-align: right;
  color: #F2F2F2;
}


.container__video {
  width: 24.167vw;
  height: 24.167vw;
  background: #DB1023;
  transform: rotate(40deg);
  position: absolute;

}

.button__portfolio {
  width: 21.771vw;
  height: 3.542vw;
  display: flex;
  align-items: center;
  position: absolute;
  left: 7.604vw;
  bottom: 15.781vw;
}

.show {
  width: 15.052vw;
  height: 3.542vw;
  margin-right: 2.083vw;
}

.arrow {
  width: 4.635vw;
  height: 1vw;
}


.portfolio_block__redisign__one {
  transform: rotate(130deg);
  width: 50.417vw;
  height: 24.167vw;
  top: 24vw;
  right: 31vw;
  z-index: 333;
  position: absolute;
  display: flex;
  justify-content: space-between;

}

.portfolio_block__redisign__two {
  width: 50.417vw;
  height: 24.167vw;
  bottom: 15vw;
  right: 10vw;
  z-index: 333;
  transform: rotate(40deg);
  position: absolute;
  display: flex;
  justify-content: space-between;

}

.redisign__one {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24.167vw;
  height: 24.167vw;
  overflow: hidden;
  cursor: pointer;
  background: linear-gradient(228deg, #000 -1.4%, rgba(0, 0, 0, 0.00) 62.01%);

}

.shadow_container {
  position: absolute;
  width: 24.167vw;
  height: 24.167vw;
  background: linear-gradient(228deg, #000 -1.4%, rgba(0, 0, 0, 0.00) 62.01%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.shadow_container_two {
  position: absolute;
  width: 24.167vw;
  height: 24.167vw;
  background: linear-gradient(338deg, #000 -1.4%, rgba(0, 0, 0, 0.00) 62.01%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.redisign__two {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24.167vw;
  height: 24.167vw;
  overflow: hidden;
  background: linear-gradient(228deg, #000 -1.4%, rgba(0, 0, 0, 0.00) 62.01%);

}

.redisign__video__one {
  position: absolute;
  top: -9vw;
  left: -19vw;
  width: 61vw;
  height: 41vw;
  transform: rotate(-130deg);
}

.redisign__video__two {
  position: absolute;
  top: -6vw;
  left: -21vw;
  width: 61vw;
  height: 41vw;
  transform: rotate(321deg);
}
.redisign__photo__one {
  transform: rotate(-130deg);
  height: 34.167vw;
}

.redisign__photo__two {
  transform: rotate(321deg);
  height: 36.167vw;
}
.video__wrapper__one {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  transform: rotate(-130deg);
  width: 17.135vw;
  height: 8.906vw;
  position: absolute;
}

.video__wrapper__two {
  transform: rotate(319deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  width: 17.135vw;
  height: 8.906vw;
  z-index: 3;
  position: absolute;
}

.play {
  width: 2.552vw;
  height: 2.552vw;
  margin-bottom: 3.490vw;
}

.video__title {
  font-family: 'Benzin-Regular';
  font-size: 1.354vw;
  line-height: 140%;
  text-transform: uppercase;
  color: #F2F2F2;
}


.doing__title {
  display: none;
}

.doing__title__portfolio {
  display: none;
}

.doing__portfolio__button {
  display: none;
}

.title__img__mobile {
  display: none;
}

.background__mobile {
  display: none;
}

.background__mobile__two {
  display: none;
}


@media screen and(max-width: 991px) {
  .video__wrapper {
    position: relative;
    display: block;
    width: 29.333vw;
    height: 29.333vw;
    z-index: -1;
  }


  .redisign__one {
    position: absolute;
    z-index: 0;

  }
  .redisign__two {
    position: absolute;
    z-index: 0;

  }
  .background__mobile__two {
    transform: rotate(17deg);
    display: block;
    position: absolute;
    left: 0;
    top: 178vw;
    z-index: -1;
    height: 34.667vw;
    width: 118.933vw;
  }
  .background__mobile {
    display: block;
    position: absolute;
    left: 0;
    top: 91vw;
    z-index: -1;
    height: 106.933vw;
    width: 100%;

  }
  .pab {
    display: none;
  }
  .title__img {
    display: none;
  }
  .portfolio__container {
    overflow: hidden;
    width: 100%;
    height: 240.000vw;
    position: relative;
    background-image: none;
  }
  .portfolio__title {
    display: none;
  }
  .title__img__mobile {
    position: absolute;
    bottom: 39vw;
    left: 0;
    width: 59.733vw;
    height: 127.200vw;
    display: block;
  }

  .doing__title__portfolio {
    position: absolute;
    right: 7vw;
    top: 2vw;
    margin-bottom: 16.533vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 72.533vw;
    height: 67.200vw;
  }
  .doing__title__text__portfolio {
    width: 42.667vw;
    height: 5.333vw;
    text-align: right;
    font-size: 5.333vw;
    font-family: 'Benzin-ExtraBold';
    line-height: 6.133vw;
    color: #FFFFFF;
    margin-bottom: 0;
  }
  .doing__title__text__small__text__portfolio {
    font-size: 3.733vw;
    line-height: 130%;
    text-align: right;
  }
  .doing__span__container__portfolio {
    display: flex;
    justify-content: right;
    width: 50.667vw;
    height: 0.267vw;
    margin-bottom: 6.533vw;
    margin-top: 6.800vw;

  }
  .doing__span__one__portfolio {
    height: 0.267vw;
    width: 25.019vw;
    background: rgba(255, 255, 255, 1);

  }
  .doing__span__two__portfolio {
    height: 0.267vw;
    width: 25.019vw;
    background: #db1023;

  }
  .doing__portfolio__button {
    position: absolute;
    top: 71vw;
    right: 7vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 9.333vw;
    width: 54.667vw;
  }
  .portfolio__button__mobile {
    height: 9.333vw;
    width: 54.667vw;
    display: block;
  }
  .button__portfolio {
    display: none;
  }
  .portfolio_block__redisign__one {
    top: 115vw;
    left: 39vw;
    transform: rotate(90deg);
    width: 64vw;
    height: 41.333vw;
  }
  #prod_left1 {
    transform: rotate(320deg);
    top: 12vw;
    left: 1vw;
    z-index: 60;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 29.333vw;
    height: 29.333vw;
    overflow: hidden;
  }
  #prod_left2 {
    transform: rotate(319deg);
    z-index: 60;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 29.333vw;
    height: 29.333vw;
    overflow: hidden;
  }


  .redisign__video__one {
    top: -11vw;
    left: -28vw;
    width: 81vw;
    height: 50vw;
    transform: rotate(309deg);
  }
  .redisign__photo__one {
    height: 50vw;
    transform: rotate(309deg);
  }
  .shadow_container {
    position: absolute;
    width: 29.333vw;
    height: 29.333vw;
    background: linear-gradient(327deg, #000 -1.4%, rgba(0, 0, 0, 0.00) 62.01%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
  }
  .shadow_container_two {
    position: absolute;
    width: 29.333vw;
    height: 29.333vw;
    background: linear-gradient(200deg, #000 -1.4%, rgba(0, 0, 0, 0.00) 62.01%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
  }
  .video__wrapper__one {

    transform: rotate(310deg);
    width: 17.135vw;
    height: 8.906vw;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .video__wrapper__two {

    transform: rotate(219deg);
    width: 17.135vw;
    height: 8.906vw;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

  }
  .portfolio_block__redisign__two {
    width: 64.000vw;
    height: 29.333vw;
    bottom: 38vw;
    right: 31vw;
    z-index: 0;
    transform: rotate(140deg);
    position: absolute;
    display: flex;
    justify-content: space-between;
  }
  .redisign__two {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 29.333vw;
    height: 29.333vw;
    overflow: hidden;
  }
  .redisign__video__two {
    top: -11vw;
    left: -28vw;
    width: 81vw;
    height: 50vw;
    transform: rotate(220deg);
  }
  .redisign__photo__two {
    height: 50vw;
    transform: rotate(220deg);
  }

  .play {
    position: relative;
    z-index: 6;
    width: 5.067vw;
    height: 5.067vw;
    margin-bottom: 3.490vw;
  }
  .video__title {
    position: relative;
    z-index: 6;
    text-align: center;
    font-family: 'Benzin-Regular';
    font-size: 1.4vw;
    line-height: 140%;
    text-transform: uppercase;
    color: #F2F2F2;
  }
}


</style>